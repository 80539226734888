import { createModel } from '@rematch/core';
import { changeRegulatorData, getAllEnumsData } from '@/apis/app';
import type { RootModel } from '.';

const defaultCollapsed: boolean = window.innerWidth < 1200;

export const app = createModel<RootModel>()({
  state: {
    publicLoading: false,
    collapsed: defaultCollapsed,
    currentRegulator: 'asic',
    lang: sessionStorage.getItem('currentLang') || 'en',
    userInfo: {
      username: '',
      userId: '',
      phoneNumber: '',
      clientId: '',
      clientSecret: '',
    },
    countryList: [], // public
    languageList: [], // public
    dataSourceMT: [], // public
    serverEnum: {
      // public
      liveServer: [],
      demoServer: [],
    },
    countryCodes: [], // public
    clientStatusCodes: [], // public
    websiteIdList: [], // public
    rcAuthToken: '',
    isFirstLogin: '', // public
    isPermission: false, // public
    rcAuthMessage: {
      clientId: '',
      clientSecret: '',
    },
    rcCallClientId: '',
    rcRingNumber: '',
    loginState: localStorage.getItem('sws-loginState') || '', // 登录状态
  },
  reducers: {
    setPermission: (state, payload: boolean = false) => {
      return {
        ...state,
        isPermission: payload,
      };
    },
    setPublicLoading: (state, payload: boolean = false) => {
      return {
        ...state,
        publicLoading: payload,
      };
    },
    setCollapsed: (state, payload: boolean = false) => {
      return {
        ...state,
        collapsed: payload,
      };
    },
    setUserInfo: (state, payload: any) => {
      sessionStorage.setItem('currentUID', payload.userId);
      return {
        ...state,
        userInfo: payload,
      };
    },
    updateLang: (state, payload: string) => {
      sessionStorage.setItem('currentLang', payload);
      localStorage.setItem('currentLang', payload);
      return {
        ...state,
        lang: payload,
      };
    },
    updateRegulator: (state, payload: string) => {
      sessionStorage.setItem('currentRegulator', payload);
      localStorage.setItem('currentRegulator', payload);
      return {
        ...state,
        currentRegulator: payload,
      };
    },
    setCountryList: (state, payload: any) => {
      return {
        ...state,
        countryList: payload,
      };
    },
    setLanguageList: (state, payload: any) => {
      return {
        ...state,
        languageList: payload,
      };
    },
    setDataSourceMT: (state, payload: any) => {
      return {
        ...state,
        dataSourceMT: payload,
      };
    },
    setServerEnum: (state, payload: any) => {
      return {
        ...state,
        serverEnum: payload,
      };
    },
    setRcAuthToken: (state, payload: any) => {
      return {
        ...state,
        rcAuthToken: payload,
      };
    },
    setLoginStatus: (state, payload: any) => {
      return {
        ...state,
        isFirstLogin: payload,
      };
    },
    setCountryCodes: (state, payload: any) => {
      return {
        ...state,
        countryCodes: payload,
      };
    },
    setRcAuthMessage: (state, payload: any) => {
      return {
        ...state,
        rcAuthMessage: payload,
      };
    },
    setRcCallClientId: (state, payload: any) => {
      return {
        ...state,
        rcCallClientId: payload,
      };
    },
    setRcRingNumber: (state, payload: any) => {
      return {
        ...state,
        rcRingNumber: payload,
      };
    },
    setLoginState: (state, payload: string) => {
      localStorage.setItem('sws-loginState', payload);
      return {
        ...state,
        loginState: localStorage.getItem('sws-loginState') || '',
      };
    },
    setClientStatusCodes: (state, payload: any) => {
      return {
        ...state,
        clientStatusCodes: payload,
      };
    },
    setWebsiteIdList: (state, payload: any) => {
      return {
        ...state,
        websiteIdList: payload,
      };
    },
  },
  effects: (dispatch) => ({
    async changeRegulator(val) {
      return new Promise((resolve, reject) => {
        changeRegulatorData(val)
          .then((res) => {
            resolve(res);
            this.updateRegulator(val);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    async getAllEnums() {
      return new Promise((resolve, reject) => {
        getAllEnumsData()
          .then((res: any) => {
            if (res.success) {
              const { data } = res;
              this.setCountryList(data.CountryEnum || []);
              this.setLanguageList(data.LocaleEnum || []);
              this.setServerEnum({
                liveServer: data.LiveServer || [],
                demoServer: data.DemoServer || [],
              });
              this.setDataSourceMT(data.DataSource_MT_Enum || []);
              this.setCountryCodes(data.CountryPhoneCode || []);
              this.setClientStatusCodes(data.TimeLineStepEnum || []);
              this.setWebsiteIdList(data.websiteId || []);
              resolve(res.data);
            }
          })
          .catch((res) => {
            reject(res);
          });
      });
    },
  }),
});
