import la from '../LazyComponent';

const ClientManagement = la(() => import('@/pages/ClientManagement'));
const ClientInformation = la(() => import('@/pages/ClientInformation'));
const ClientDetails = la(() => import('@/pages/ClientDetails'));

const ClientManagementRoutes = [
  {
    path: '/clients',
    element: ClientManagement,
    meta: {
      key: 'clients',
    },
  },
  {
    path: '/clients/information/:id',
    element: ClientInformation,
    meta: {
      key: 'clients',
    },
  },
  {
    path: '/clients/detail/:id',
    element: ClientDetails,
    meta: {
      key: 'clients',
    },
  },
];
export default ClientManagementRoutes;
