import React from 'react';
import { createBrowserRouter } from 'react-router-dom';
import la from './LazyComponent';
import ErrorBoundary from './ErrorBoundary';
import taskManagementRoutes from './modules/taskManagement';
import SalesWorkStationRoutes from './modules/salesWorkStation';
import LeadManagementRoutes from './modules/leadManagement';
import ClientManagementRoutes from './modules/clientsManagement';
import MessagesRoutes from './modules/messages';
import AdminStatisticsRoutes from './modules/AdminStatistics';

const MainLayout = la(() => import('@/components/MainLayout'));
const NotFound = la(() => import('@/pages/404'));
const NoAuth = la(() => import('@/pages/403'));
const Login = la(() => import('@/pages/Login'));
const OtherEntry = la(() => import('@/pages/OtherEntry'));
const RingCentralTest = la(() => import('@/pages/RingCentralCallTest'));

const config: any = createBrowserRouter([
  {
    path: '/',
    element: MainLayout,
    errorElement: <ErrorBoundary />,
    // exact: true,
    children: [
      // has header and sidebar layouts
      ...taskManagementRoutes,
      ...SalesWorkStationRoutes,
      ...LeadManagementRoutes,
      ...ClientManagementRoutes,
      ...MessagesRoutes,
      ...AdminStatisticsRoutes,
      {
        path: '/test/call',
        element: RingCentralTest,
      },
    ],
  },
  {
    path: '*',
    element: NotFound,
  },
  {
    path: '/403',
    element: NoAuth,
  },
  {
    path: '/login',
    element: Login,
  },
  {
    path: '/otherEntry',
    element: OtherEntry,
  },
]);

export default config;
