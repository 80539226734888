import React, { useEffect } from 'react';
import { RouterProvider } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ConfigProvider, message } from 'antd';
import Loading from '@/components/PageLoading';
import useBrandHandler from '@/hooks/useBrandHandler';
import routers from '@/routes';

import { store } from './store';

import '@/common/antdOverride.less';

message.config({
  maxCount: 1,
  top: 40,
});

function App() {
  const { CURRENT_BRAND, getMainTheme } = useBrandHandler();
  const antdTheme = getMainTheme();
  console.log(`CURRENT_BRAND`, CURRENT_BRAND);
  useEffect(() => {
    store.dispatch.app.getAllEnums();
  }, []);

  return (
    <ConfigProvider theme={antdTheme}>
      <Provider store={store}>
        <RouterProvider router={routers} fallbackElement={<Loading />} />
      </Provider>
    </ConfigProvider>
  );
}

export default App;
